import {
  React,
  useState,
  useEffect,
  Chip,
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  DatePicker,
  makeStyles,
  LocationSelect,
  ServiceSelect,
  IndustrySelect,
  useLocation,
  addImage,
  ContractingEntityComponent,
  useNavigate,
  SectorOfWorkSelect,
  OtherTagsInput,
  TextareaAutosize,
  projectService,
  Swal,
  loader,
  ClientSelectionComponent,
  CurrencyTextField,
  OrganizationEntitySelect,
  qsnoimage,
  getCountries,
  CreateProjectFormVariables,
} from "./CreateProjectFormImports";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import CustomTextField from "./CustomTextFile";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "10px",
  },
  input: {
    width: "100%",
    color: "black",
    "&::placeholder": {
      color: "black",
      opacity: 1,
    },
  },
}));

const CreateProjectForm = () => {
  const {
    projectName,
    setProjectName,
    description,
    setDescription,
    selectedClient,
    setSelectedClient,
    selectedContractor,
    setSelectedContractor,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedOrgEntity,
    setSelectedOrgEntity,
    orgEntities,
    setOrgEntities,
    locations,
    setLocations,
    industry,
    setIndustry,
    selectedFile,
    setSelectedFile,
    selectedServices,
    setSelectedServices,
    selectedSector,
    setSelectedSector,
    sector,
    setSector,
    selectedIndustry,
    setSelectedIndustry,
    team,
    setTeam,
    managers,
    setManagers,
    tags,
    setTags,
    selectedLocations,
    setSelectedLocations,
    chipData,
    setChipData,
    budget,
    setBudget,
    clients,
    setClients,
    services,
    setServices,
    postId,
    setPostId,
    currency,
    setCurrency,
    loading,
    setLoading,
    imageName,
    setImageName,
    imageUrl,
    setImageUrl,
    principalClientsData,
    setPrincipalClientData,
    contractorData,
    setContractorData,
    countries,
    setCountries,
    defaultLocation,
    setDefaultLocation,
  } = CreateProjectFormVariables();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const projectDetail = location.state?.projectDetail;
  const userName = localStorage.getItem("userName");
  const [projectSlug, setProjectSlug] = useState("");
  const [internalTeam, setInternalTeam] = useState([]);
  const [externalTeam, setExternalTeam] = useState([]);
  const [convertedBudget, setConvertedBudget] = useState(0);
  const [conversionRate, setConversionRate] = useState({
    USDToINR: 0,
    INRToUSD: 0,
  });
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    getCountries()
      .then((response) => {
        const clientCountries = response.data.filter(
          (country) => country.location !== undefined
        );

        setCountries(clientCountries);

        if (clientCountries.length > 0) {
          setDefaultLocation(clientCountries[0].location);
        }
      })
      .catch((error) => {
        console.log("Error fetching countries:", error);
      });
  }, []);

  useEffect(() => {
    if (projectDetail) {
      setProjectSlug(generateSlug(projectDetail?.projectname));
      setPostId(projectDetail._id || "");
      setProjectName(projectDetail.projectname || "");
      setDescription(projectDetail.description || "");
      setImageUrl(projectDetail?.imageurl);
      setInternalTeam(projectDetail?.internalTeam || []);
      setExternalTeam(projectDetail?.externalTeam || []);
      setCurrency(projectDetail?.currency);
      setSelectedStartDate(projectDetail.fromdate || "");
      setSelectedEndDate(projectDetail.todate || "");
      setSelectedOrgEntity(projectDetail.orgEntities || []);
      setBudget(projectDetail.budget || 0);
      setSelectedLocations(
        projectDetail?.projectlocation?.map((loc) => loc) || []
      );
      setPrincipalClientData(
        projectDetail?.principalclientform?.map((loc) => loc) || []
      );
      setContractorData(
        projectDetail?.contractorclientform?.map((loc) => loc) || []
      );
      setSelectedServices(projectDetail?.servicetype?.map((loc) => loc) || []);
      setSelectedSector(projectDetail?.sectorwork?.map((loc) => loc) || []);
      setSelectedIndustry(projectDetail?.industry?.map((loc) => loc) || []);
      setChipData(projectDetail?.others?.map((tag) => tag) || []);
    } else {
      setProjectName("");
      setDescription("");
      setLocations([]);
    }
  }, [projectDetail]);

  useEffect(() => {
    if (selectedFile) {
      const uploadSuccess = uploadImage();
    }
  }, [selectedFile]);

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleOrgEntityChange = (event) => {
    setSelectedOrgEntity(event.target.value);
  };

  const handleStartDateSelect = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}/${month}/${day}`;
    // console.log("start date", formattedDate);
    setSelectedStartDate(formattedDate);
  };

  const handleEndDateSelect = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}/${month}/${day}`;
    // console.log("End date", formattedDate);
    setSelectedEndDate(formattedDate);
  };

  const formatDate = (date) => {
    if (!date) return "";

    // Check if the date is a string and contains "T"
    if (typeof date === "string" && date.includes("T")) {
      // Extract the date part from the string
      const extractedDate = date.split("T")[0];
      return extractedDate;
    } else {
      return date;
    }
  };

  const generateSlug = (str) => {
    return str
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "")
      .replace(/--+/g, "-");
  };

  function toISTDate(date) {
    if (!date) {
      return;
    }
    console.log("date", date);
    const offset = 5.5 * 60;
    const utcDate = new Date(date.getTime() + offset * 60 * 1000);
    return utcDate.toISOString().slice(0, 10);
  }

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!projectName) newErrors.projectName = "Project name is required!";
    if (!description) newErrors.description = "Description is required!";
    if (!selectedStartDate)
      newErrors.startDate = "Please specify the start date!";
    if (!selectedEndDate) newErrors.endDate = "Please specify the end date!";
    if (!budget) newErrors.budget = "Budget is required!";
    if (selectedLocations.length === 0)
      newErrors.locations = "Project location is required!";
    if (principalClientsData.length === 0)
      newErrors.principalClientsData = "Client details is required!";

    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // console.log("errors", validationErrors); // Log validation errors
      return;
    }
    localStorage.removeItem("projectData");
    setLoading(true);

    if (selectedFile && imageUrl === "") {
      // console.log("No Image Url ");
      setLoading(false);
      return;
    } else {
      // console.log("image Url testing", imageUrl);
    }

    // console.log("This is the image url : ", imageUrl);

    const formData = {
      created_username: userName,
      created_datetime: formatDate(new Date()),
      updated_username: "",
      updated_datetime: "",
      publishproject: "n",
      closeproject: "n",
      image: imageName,
      imageurl: imageUrl === addImage ? qsnoimage : imageUrl,
      principalclientform: principalClientsData,
      contractorclientform: contractorData,
      projectname: projectName,
      description: description,
      projectlocation: selectedLocations,
      fromdate: toISTDate(selectedStartDate),
      todate: toISTDate(selectedEndDate),
      currency: currency,
      budget: budget,
      durations: 0,
      servicetype: selectedServices,
      industry: selectedIndustry,
      sectorwork: selectedSector,
      others: chipData,
      orgEntities: selectedOrgEntity,
      internalTeam: internalTeam,
      externalTeam: externalTeam,
      subcontractors: [],
      collaborators: [],
    };
    const formDataSess = localStorage.setItem(
      "projectData",
      JSON.stringify(formData)
    );
    // console.log("formDataSess", formData);

    //Post method is in internal team

    navigate("/team");
    setLoading(false);
  };

  const handleUpdate = async (event) => {
    setLoading(true);
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log("errors", validationErrors); // Log validation errors
      return;
    }
    // console.log("Updating");
    if (selectedFile) {
      const uploadSuccess = await uploadImage();

      if (!uploadSuccess) {
        setLoading(false);
        return;
      }
    }
    // console.log("imageUrl update ", imageUrl);

    if (selectedFile && imageUrl === "") {
      // console.log("No Image Url ");
      setLoading(false);
      return;
    } else {
      // console.log("image Url testing", imageUrl);
    }

    const formData = {
      _id: postId,
      updated_username: userName,
      updated_datetime: formatDate(new Date()),
      image: imageName,
      slug: projectSlug,
      // slug: generateSlug(projectName),
      imageurl: imageUrl === addImage ? qsnoimage : imageUrl,
      principalclientform: principalClientsData,
      contractorclientform: contractorData,
      projectname: projectName,
      description: description,
      projectlocation: selectedLocations,
      fromdate: selectedStartDate,
      todate: selectedEndDate,
      currency: currency,
      budget: budget,
      durations: 0,
      servicetype: selectedServices,
      industry: selectedIndustry,
      sectorwork: selectedSector,
      others: chipData,
      partners: projectDetail.partners,
      orgEntities: selectedOrgEntity,
      internalTeam: internalTeam,
      externalTeam: externalTeam,
      subcontractors: [],
      collaborators: [],
    };

    try {
      setLoading(true);
      const formDataParsed = JSON.stringify(formData);
      const formDataUpload = JSON.parse(formDataParsed);
      const response = await projectService.updateProject(formDataUpload);
      // console.log("respose from update", response.data);
      const newId = response?.data?.data?.value._id;
      if (response.status === 200) {
        Swal.fire({
          text: "Project updated successfully!",
          icon: "success",
        });
        setLoading(false);
        navigate(`/project/${newId}`);
      } else {
        setLoading(false);
        console.error("Failed to update project:", response.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating project:", error);
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleTagsChange = (event) => {
    setTags(event.target.value);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const allowedMimeTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (file) {
      if (!allowedMimeTypes.includes(file.type)) {
        await Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Please upload an image file (PNG, JPEG, JPG).",
        });
        return;
      }

      if (file.size > maxSizeInBytes) {
        await Swal.fire({
          icon: "error",
          title: "File too large",
          text: "File size exceeds 5 MB. Please upload a smaller image.",
        });
        return;
      }

      setSelectedFile(file);

      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
    } else {
      await Swal.fire({
        icon: "warning",
        title: "No file selected",
        text: "Please choose a file to upload.",
      });
    }
  };

  const uploadImage = async () => {
    if (!selectedFile) return false;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await projectService.moveImage(formData);

      const newImageUrl = response.data.movedimgurl;

      setImageUrl(newImageUrl);
      // console.log("imageUrl for upload", newImageUrl);
      setImageName(response?.data?.movedimg);

      return true;
    } catch (error) {
      console.error("Error uploading image:", error);
      return false;
    }
  };

  const handleBudgetChange = (event) => {
    const value = parseFloat(event.target.value);
    setBudget(isNaN(value) ? 0 : value);
  };
  const handleCancelCreation = () => {
    navigate("/");
  };

  function handleDelete(option) {
    setSelectedLocations(
      selectedLocations.filter((location) => location !== option)
    );
  }

  const handleStartDateChange = (date) => {
    if (date) {
      const istDate = dayjs(date).tz("Asia/Kolkata");
      const jsDate = istDate.toDate();
      setSelectedStartDate(jsDate);
    }
  };

  // useEffect(() => {
  //   console.log("Selected Start Date:", selectedStartDate);
  // }, [selectedStartDate]);

  const handleEndDateChange = (date) => {
    if (date) {
      const istDate = dayjs(date).tz("Asia/Kolkata");
      const jsDate = istDate.toDate();

      setSelectedEndDate(jsDate);
    }
  };

  return (
    <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid item xs={12} sm={6} lg={4} style={{ paddingBottom: "2rem" }}>
            <CustomTextField
              onChange={handleProjectNameChange}
              value={projectName}
              style={{ borderColor: errors.projectName ? "red" : "initial" }}
            />
            {errors.projectName && (
              <FormHelperText
                style={{
                  color: "red",
                  marginLeft: "0.2rem",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                }}
              >
                {errors.projectName}
              </FormHelperText>
            )}
            {/* <TextField
              style={{ minWidth: "60rem" }}
              className="transparent-border"
              variant="outlined"
              placeholder="Enter Project Name *"
              value={projectName}
              onChange={handleProjectNameChange}
              required
              InputProps={{
                className: "transparent-border-hover",
                style: {
                  fontSize: "2.5rem",
                  fontWeight: "550",
                  color: "black",
                  fontFamily: "Poppins",
                },
                classes: {
                  input: classes.input,
                },
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", gap: 0, marginBottom: "10px" }}
          >
            <div style={{ flex: "0 0 15.5rem" }}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="raised-button-file">
                <img
                  src={imageUrl}
                  alt="Upload"
                  style={{
                    width: "85%",
                    marginLeft: "1rem",
                    height: "12rem",
                    borderRadius: "10px",
                    opacity: "100%",
                    cursor: "pointer",
                  }}
                />
              </label>
            </div>

            <div
              style={{ flex: "1", display: "flex", flexDirection: "column" }}
            >
              <div>
                <FormHelperText>Description *</FormHelperText>
                <TextareaAutosize
                  minRows={3}
                  maxRows={3}
                  style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    minWidth: "100%",
                    minBlockSize: "100%",
                    resize: "vertical",
                  }}
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="Description"
                  required
                />
                {errors.description && (
                  <FormHelperText
                    style={{
                      color: "red",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                    }}
                  >
                    {errors.description}
                  </FormHelperText>
                )}
              </div>
              <br />
              <br />

              <hr />
              <div>
                <Grid item xs={12}>
                  <div className="client-details-container">
                    <div className="create-project-part-two">
                      <h5
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Client Details
                      </h5>

                      <ClientSelectionComponent
                        selectedClient={selectedClient}
                        setSelectedClient={setSelectedClient}
                        clients={clients}
                        setClients={setClients}
                        principalClientsData={principalClientsData}
                        setPrincipalClientData={setPrincipalClientData}
                        countries={countries}
                        setCountries={setCountries}
                        defaultLocation={defaultLocation}
                        setDefaultLocation={setDefaultLocation}
                      />

                      <ContractingEntityComponent
                        contractorData={contractorData}
                        setSelectedContractor={setContractorData}
                        clients={clients}
                        setClients={setClients}
                        selectedContractor={selectedContractor}
                        setContractorData={setContractorData}
                        countries={countries}
                        setCountries={setCountries}
                        defaultLocation={defaultLocation}
                        setDefaultLocation={setDefaultLocation}
                      />
                      {errors.principalClientsData && (
                        <FormHelperText
                          style={{
                            color: "red",
                            fontFamily: "Poppins",
                            fontSize: "13px",
                          }}
                        >
                          {errors.principalClientsData}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                </Grid>
              </div>

              <hr />
              <div className="containerx">
                <h5 className="header">Logistical Information</h5>

                <div className="row">
                  <div className="item">
                    <FormHelperText style={{ fontFamily: "Poppins" }}>
                      Targeted Location of Project *
                    </FormHelperText>
                    <LocationSelect
                      locations={locations}
                      setLocations={setLocations}
                      selectedLocations={selectedLocations}
                      setSelectedLocations={setSelectedLocations}
                      required
                    />
                    {errors.locations && (
                      <FormHelperText
                        style={{
                          color: "red",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                        }}
                      >
                        {errors.locations}
                      </FormHelperText>
                    )}
                    {selectedLocations.map((option, index) => (
                      <Chip
                        variant="filled"
                        label={option.location}
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#ebebeb",
                          color: "#7a8093",
                          marginRight: "0.2rem",
                          fontFamily: "Poppins",
                          marginTop: "0.2rem",
                        }}
                        key={index}
                      />
                    ))}
                  </div>

                  <div className="item">
                    <FormHelperText style={{ fontFamily: "Poppins" }}>
                      Start *
                    </FormHelperText>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth>
                        <DesktopDatePicker
                          format="DD/MM/YYYY"
                          value={dayjs(selectedStartDate)}
                          onChange={handleStartDateChange}
                          sx={{
                            "& .MuiInputBase-root": {
                              maxHeight: 38,
                              color: "grey",
                              fontSize: "12px",
                            },
                            "& .MuiCalendarPicker-root": {
                              maxHeight: 400,
                            },
                            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                          }}
                        />
                      </FormControl>
                      {errors.startDate && (
                        <FormHelperText
                          style={{
                            color: "red",
                            fontFamily: "Poppins",
                            fontSize: "13px",
                          }}
                        >
                          {errors.startDate}
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </div>

                  <div className="item">
                    <FormHelperText style={{ fontFamily: "Poppins" }}>
                      End *
                    </FormHelperText>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth>
                        <DesktopDatePicker
                          format="DD/MM/YYYY"
                          value={dayjs(selectedEndDate)}
                          onChange={handleEndDateChange}
                          sx={{
                            "& .MuiInputBase-root": {
                              maxHeight: 38,
                              color: "grey",
                              fontSize: "12px",
                            },
                            "& .MuiCalendarPicker-root": {
                              maxHeight: 400,
                            },
                            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                          }}
                        />
                      </FormControl>
                      {errors.endDate && (
                        <FormHelperText
                          style={{
                            color: "red",
                            fontFamily: "Poppins",
                            fontSize: "13px",
                          }}
                        >
                          {errors.endDate}
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </div>

                  <div className="item">
                    <FormHelperText
                      style={{ marginBottom: "0.4rem", fontFamily: "Poppins" }}
                    >
                      Budget*
                      <div className="float-end">
                        {currency === "USD $"
                          ? `₹ ${convertedBudget.toFixed(2)}`
                          : `$ ${convertedBudget.toFixed(2)}`}
                      </div>
                    </FormHelperText>
                    <CurrencyTextField
                      conversionRate={conversionRate}
                      convertedBudget={convertedBudget}
                      setConversionRate={setConversionRate}
                      setConvertedBudget={setConvertedBudget}
                      currency={currency}
                      handleCurrencyChange={handleCurrencyChange}
                      budget={budget}
                      handleBudgetChange={handleBudgetChange}
                    />
                    {errors.budget && (
                      <FormHelperText style={{ color: "red" }}>
                        {errors.budget}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              </div>

              <br />

              <hr />
              <div className="containerx">
                <h5 className="header">Tagging and Identification</h5>

                <div className="row">
                  <div className="item">
                    <FormHelperText style={{ fontFamily: "Poppins" }}>
                      Service Type*
                    </FormHelperText>
                    <ServiceSelect
                      selectedServices={selectedServices}
                      setSelectedServices={setSelectedServices}
                      services={services}
                      setServices={setServices}
                    />
                    <div className="chip-container">
                      {selectedServices.map((option, index) => (
                        <Chip
                          key={index}
                          variant="filled"
                          label={option.name}
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ebebeb",
                            color: "#7a8093",
                            marginRight: "0.2rem",
                            fontFamily: "Poppins",
                            marginTop: "0.2rem",
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="item">
                    <FormHelperText style={{ fontFamily: "Poppins" }}>
                      Industry*
                    </FormHelperText>
                    <IndustrySelect
                      selectedIndustry={selectedIndustry}
                      setSelectedIndustry={setSelectedIndustry}
                      industry={industry}
                      setIndustry={setIndustry}
                    />
                    <div className="chip-container">
                      {selectedIndustry.map((option, index) => (
                        <Chip
                          key={index}
                          variant="filled"
                          label={option.name}
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ebebeb",
                            color: "#7a8093",
                            marginRight: "0.2rem",
                            fontFamily: "Poppins",
                            marginTop: "0.2rem",
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="item">
                    <FormHelperText style={{ fontFamily: "Poppins" }}>
                      Sector of Work*
                    </FormHelperText>
                    <SectorOfWorkSelect
                      selectedSector={selectedSector}
                      setSelectedSector={setSelectedSector}
                      sector={sector}
                      setSector={setSector}
                    />
                    <div className="chip-container">
                      {selectedSector.map((option, index) => (
                        <Chip
                          key={index}
                          variant="filled"
                          label={option.name}
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ebebeb",
                            color: "#7a8093",
                            marginRight: "0.2rem",
                            fontFamily: "Poppins",
                            marginTop: "0.2rem",
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="item">
                    <FormHelperText style={{ fontFamily: "Poppins" }}>
                      Other Tags
                    </FormHelperText>
                    <OtherTagsInput
                      value={tags}
                      onChange={handleTagsChange}
                      chipData={chipData}
                      setChipData={setChipData}
                    />
                  </div>
                </div>
                <br />
                <br />

                <br />
                <br />
                <br />
                <br />

                <div className="button-container">
                  <button
                    className="btn cancel-btn"
                    onClick={handleCancelCreation}
                  >
                    <h6 style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                      Cancel
                    </h6>
                  </button>
                  {projectDetail ? (
                    <button className="btn update-btn" onClick={handleUpdate}>
                      <h6 style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                        Update
                      </h6>
                    </button>
                  ) : (
                    <button className="btn submit-btn" onClick={handleSubmit}>
                      <h6 style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                        Next: Add Team
                      </h6>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateProjectForm;
