import React, { useState, useEffect, useRef } from "react";
import { customStyles, customTeamStyles } from "../models/SelectStyles";
import { Autocomplete } from "@mui/material";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import qsnoimage from "../assets/noimage.png";
import {
  getOrganization,
  getEmployees,
  getProjectRoles,
} from "../services/FormService";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import RoleForm from "./ProjectRoleForm";
import {
  handleDeleteManager,
  handleDeletePartner,
  handleEditManager,
  getFilteredDesignations,
  getFilteredConsultantDesignations,
  handleEditMember,
  handleEditPartner,
  handleDeleteMember,
  handleEditPrincipal,
  handleDeletePrincipal,
  handleEditColead,
  handleDeleteColead,
  handleEditExternalMember,
  handleDeleteExternalMember,
} from "../utils/TeamUtils";
import useStyles from "../utils/TeamFormStyles";
import { Button } from "bootstrap";

function TeamFormComponent({
  description,
  projectname,
  setLoading,
  projectImage,
  projectPartner,
  internalTeam,
  setInternalTeam,
  externalTeam,
  setExternalTeam,
  project_id,
}) {
  const [onRollEmployees, setOnRollEmployees] = useState([]);
  const [externalMembers, SetExternalMembers] = useState([]);
  const [existingRoles, setExistingRoles] = useState([]);
  const [isTextboxOpen, setIsTextboxOpen] = useState(false);
  const [showNewButton, setShowNewButton] = useState(false);
  const [showExternalNewButton, setShowExternalNewButton] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedExternalMember, setSelectedExternalMember] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedExternalDesignation, setSelectedExternalDesignation] =
    useState(null);
  const [designationOpen, setDesignationOpen] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChip, setSelectedChip] = useState(null);
  const [selectedExternalChip, setSelectedChipExternalChip] = useState(null);
  const designationRef = useRef(null);
  const classes = useStyles();

  //This variable will truncate and show the description dynamically
  const [showFullDescription, setShowFullDescription] = useState(false);
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getTruncatedDescription = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) return text;
    return `${words.slice(0, wordLimit).join(" ")} ...`;
  };

  const descriptionText = showFullDescription
    ? description
    : getTruncatedDescription(description || "", 60);

  useEffect(() => {
    getEmployees().then((response) => {
      const filteredOnRollEmployees = response.data.filter(
        (employee) =>
          employee.employment_status.slug === "on-roll" &&
          employee.type !== "Part Time Consultancy"
      );
      setOnRollEmployees(filteredOnRollEmployees);

      const filteredExternalTeam = response.data.filter(
        (employee) => employee.type === "Part Time Consultancy"
      );
      SetExternalMembers(filteredExternalTeam);
    });

    getProjectRoles().then((response) => {
      setDesignations(response.data);
    });
  }, []);

  // useEffect(() => {
  //   console.log("externalTeam", externalTeam);
  // }, [externalTeam]);

  const handleChipClick = (member) => {
    setSelectedChip(member);
    setSelectedMember(member);
    setSelectedDesignation(null);
    setDesignationOpen(true);
  };

  const handleAddMember = () => {
    const newMember = {
      name: `${selectedMember?.firstname} ${selectedMember?.lastname}`,
      id: `${selectedMember?.id}`,
      band: selectedMember?.band,
      email: selectedMember?.email,
      role: selectedDesignation?.role,
      slug: selectedDesignation?.slug,
      status: "active",
    };
    const removeFromArray = (arr, member) => {
      return arr.filter((item) => item.email !== member.email);
    };

    setInternalTeam((prevTeamMembers) =>
      removeFromArray(prevTeamMembers, newMember)
    );
    setExternalTeam((prevTeam) => removeFromArray(prevTeam, newMember));

    if (selectedDesignation?.slug !== "") {
      setInternalTeam((prevmembers) => [...prevmembers, newMember]);
    }

    setSelectedMember(null);
    setSelectedDesignation(null);
    setSelectedChip(null);
    setDesignationOpen(false);
  };

  const addExternalMember = () => {
    if (!selectedExternalMember || !selectedExternalDesignation) {
      return;
    }

    const newExternalMember = {
      name: `${selectedExternalMember?.firstname} ${selectedExternalMember?.lastname}`,
      id: `${selectedExternalMember?.id}`,
      email: selectedExternalMember?.email,
      role: selectedExternalDesignation?.role,
      slug: selectedExternalDesignation?.slug,
      status: "active",
    };
    console.log("newExternalMember", selectedExternalMember)

    setExternalTeam((prevExternalMembers) => [
      ...prevExternalMembers,
      newExternalMember,
    ]);

    setSelectedExternalMember(null);
    setSelectedExternalDesignation(null);
    setSelectedChip(null);
  };

  const handleClearFields = () => {
    setSelectedMember(null);
    setSelectedDesignation(null);
  };

  const handleExternalClearFields = () => {
    setSelectedExternalMember(null);
    setSelectedExternalDesignation(null);
    setSelectedChip(null);
  };

  const openRoleModal = () => {
    setIsModalOpen(true);
  };

  const closeRoleModal = () => {
    setIsModalOpen(false);
  };

  const addRole = (newRole) => {
    setDesignations([...designations, newRole]);
  };

  const handleNewButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalShow(true);
  };

  const handleExternalMenuOpen = () => {
    setShowExternalNewButton(true);
    setIsTextboxOpen(true);
  };

  const handleExternalMenuClose = () => {
    setShowExternalNewButton(false);
    setTimeout(() => {
      setIsTextboxOpen(false);
    }, 150);
  };

  const handleMenuOpen = () => {
    setShowNewButton(true);
    setIsTextboxOpen(true);
  };

  const handleMenuClose = () => {
    setShowNewButton(false);
    setTimeout(() => {
      setIsTextboxOpen(false);
    }, 150);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleDesignationChange = (newValue) => {
    setSelectedDesignation(newValue);
  };

  const chipStyle = (isSelected) => ({
    borderRadius: "5px",
    minHeight: "2.5rem",
    border: isSelected ? "2px dashed black" : "2px solid transparent",
    padding: "0.5rem",
  });

  const chipContainerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      chipContainerRef.current &&
      !chipContainerRef.current.contains(event.target)
    ) {
      setSelectedChip(null);
      setSelectedChipExternalChip(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div style={{ padding: "3rem" }}>
        <h2 style={{ fontWeight: "600", marginBottom: "1rem" }}>
          Team Members
        </h2>
        <div
          style={{
            fontFamily: "Poppins",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <img
            src={projectImage ? projectImage : qsnoimage}
            alt="Project"
            className={classes.image}
          />
          <div className={classes.content} style={{ marginLeft: "1rem" }}>
            <h4
              style={{
                color: "grey",
                fontFamily: "Poppins",
                fontWeight: "600",
              }}
            >
              {projectname}{" "}
              <span
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  opacity: "60%",
                }}
              >
                -{" "}
                {projectPartner.name ||
                  projectPartner.organization ||
                  projectPartner.client.name}
              </span>
            </h4>
            <p style={{ fontSize: "12px", maxWidth: "60%" }}>
              {descriptionText}{" "}
            </p>

            {/* Internal Team Section */}
            <h6 style={{ marginTop: "1.5rem", fontWeight: "600" }}>
              Internal Team
            </h6>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1",
                }}
              >
                {/* Select dropdown for adding members */}
                <Select
                  maxMenuHeight={200}
                  styles={customTeamStyles}
                  className={classes.autocomplete}
                  options={onRollEmployees.filter(
                    (employee) =>
                      !internalTeam.some(
                        (member) => member.email === employee.email
                      )
                  )}
                  getOptionLabel={(option) =>
                    `${option.firstname} ${option.lastname}`
                  }
                  getOptionValue={(option) => option.email}
                  isOptionSelected={(option) =>
                    selectedMember && option.email === selectedMember.email
                  }
                  value={selectedMember}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  onChange={(newValue) => setSelectedMember(newValue)}
                  placeholder="Add member"
                  isClearable={true}
                />
                <div style={{ position: "relative", marginTop: "0.3rem" }}>
                  <Select
                    className={classes.autocomplete}
                    styles={customStyles}
                    maxMenuHeight={180}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    onInputChange={handleInputChange}
                    options={getFilteredDesignations(
                      selectedMember,
                      designations
                    )}
                    getOptionLabel={(option) => option.role}
                    getOptionValue={(option) => option.slug}
                    value={selectedDesignation}
                    onChange={handleDesignationChange}
                    placeholder="Select designation"
                    components={{
                      IndicatorSeparator: null,
                    }}
                    isClearable={false}
                  />
                  {isTextboxOpen &&
                    inputValue === "" &&
                    selectedMember !== null && (
                      <button
                        style={{
                          position: "absolute",
                          right: 15,
                          bottom: "-13.2rem",
                          zIndex: 999,
                          textDecoration: "none",
                          fontFamily: "Poppins",
                          fontSize: "small",
                          cursor: "pointer",
                          border: "none",
                          color: showNewButton ? "black" : "transparent",
                          background: "none",
                          padding: 0,
                        }}
                        onClick={handleNewButtonClick}
                      >
                        +New
                      </button>
                    )}
                </div>
                <div className="float" style={{ marginTop: "0.5rem" }}>
                  {selectedMember && selectedDesignation && (
                    <button
                      className="btn btn-sm btn-primary"
                      style={{ marginRight: "0.3rem" }}
                      onClick={handleAddMember}
                    >
                      Add
                    </button>
                  )}
                  {selectedMember && selectedDesignation && (
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={handleClearFields}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>

              <div
                ref={chipContainerRef}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: "3",
                  gap: "0.3rem",
                }}
              >
                {internalTeam
                  .sort((a, b) =>
                    a.status === "inactive"
                      ? 1
                      : b.status === "inactive"
                      ? -1
                      : 0
                  )
                  .map((internaMember, index) => (
                    <Chip
                      key={`internaMember_${index}`}
                      label={
                        <div>
                          <div>{internaMember.name}</div>
                          <div style={{ opacity: 0.7, marginTop: "-0.1rem" }}>
                            {internaMember.status === "inactive"
                              ? "Off the project"
                              : internaMember.role}
                          </div>
                        </div>
                      }
                      style={chipStyle(selectedChip === internaMember)}
                      className={
                        internaMember.status === "inactive"
                          ? classes.offTheProjectChip
                          : ""
                      }
                      onDelete={handleDeleteManager(
                        internaMember,
                        internalTeam,
                        setInternalTeam,
                        setSelectedMember,
                        setSelectedDesignation,
                        project_id
                      )}
                      onClick={handleEditManager(
                        internaMember,
                        setSelectedChip,
                        setSelectedMember,
                        setSelectedDesignation,
                        setInternalTeam,
                        project_id
                      )}
                    />
                  ))}
              </div>
            </div>
            <h6 style={{ marginTop: "1.5rem", fontWeight: "600" }}>
              External Team
            </h6>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1",
                }}
              >
                {/* Select dropdown for adding members */}
                <Select
                  styles={customTeamStyles}
                  maxMenuHeight={200}
                  className={classes.autocomplete}
                  options={externalMembers}
                  getOptionLabel={(option) =>
                    option.firstname + " " + option.lastname
                  }
                  components={{
                    IndicatorSeparator: null,
                  }}
                  getOptionValue={(option) => option.email}
                  value={selectedExternalMember}
                  onChange={(newValue) => setSelectedExternalMember(newValue)}
                  placeholder="Add member"
                />
                <div style={{ position: "relative", marginTop: "0.3rem" }}>
                  <Select
                    styles={customStyles}
                    maxMenuHeight={170}
                    onInputChange={handleInputChange}
                    onMenuOpen={handleExternalMenuOpen}
                    onMenuClose={handleExternalMenuClose}
                    className={classes.autocomplete}
                    options={getFilteredConsultantDesignations(
                      selectedExternalMember,
                      designations
                    )}
                    getOptionLabel={(option) => option.role}
                    getOptionValue={(option) => option.slug}
                    value={selectedExternalDesignation}
                    onChange={(newValue) =>
                      setSelectedExternalDesignation(newValue)
                    }
                    placeholder="Select designation"
                    components={{
                      IndicatorSeparator: null,
                    }}
                  />
                  {isTextboxOpen &&
                    inputValue === "" &&
                    selectedExternalMember !== null && (
                      <button
                        style={{
                          position: "absolute",
                          right: 15,
                          bottom: "-12.6rem",
                          zIndex: 999,
                          textDecoration: "none",
                          fontFamily: "Poppins",
                          fontSize: "small",
                          cursor: "pointer",
                          border: "none",
                          color: showExternalNewButton
                            ? "black"
                            : "transparent",
                          background: "none",
                          padding: 0,
                        }}
                        onClick={handleNewButtonClick}
                      >
                        +New
                      </button>
                    )}
                </div>
                <div className="float" style={{ marginTop: "0.5rem" }}>
                  {selectedExternalMember && selectedExternalDesignation && (
                    <div className="float" style={{ marginTop: "0.5rem" }}>
                      <button
                        className="btn btn-sm btn-primary"
                        style={{ marginRight: "0.3rem" }}
                        onClick={addExternalMember}
                      >
                        Add
                      </button>

                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={handleExternalClearFields}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: "3",
                  gap: "0.3rem",
                }}
              >
                {externalTeam
                  .sort((a, b) =>
                    a.slug === "inactive" ? 1 : b.slug === "inactive" ? -1 : 0
                  )
                  .map((member, index) => (
                    <Chip
                      key={index}
                      label={
                        <div>
                          <div>{member.name}</div>
                          <div style={{ opacity: 0.7, marginTop: "-0.1rem" }}>
                            {member.status === "inactive"
                              ? "Off the project"
                              : member.role}
                          </div>
                        </div>
                      }
                      style={{
                        borderRadius: "5px",
                        minHeight: "2.5rem",
                        border:
                          selectedExternalChip === member
                            ? "2px dashed black"
                            : "2px solid transparent",
                      }}
                      className={
                        member.status === "inactive"
                          ? classes.offTheProjectChip
                          : ""
                      }
                      onDelete={handleDeleteExternalMember(
                        member,
                        setExternalTeam,
                        setSelectedExternalMember,
                        setSelectedExternalDesignation,
                        externalTeam,
                        project_id
                      )}
                      onClick={handleEditExternalMember(
                        member,
                        setSelectedChipExternalChip,
                        setSelectedExternalMember,
                        setSelectedExternalDesignation,
                        setExternalTeam,
                        project_id
                      )}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <>
        <RoleForm
          addRole={addRole}
          designations={designations}
          setDesignations={setDesignations}
          show={modalShow}
          onHide={() => setModalShow(false)}
          existingRoles={existingRoles}
          setExistingRoles={setExistingRoles}
        />
      </>
    </div>
  );
}

export default TeamFormComponent;
