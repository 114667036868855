import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditIconPng from "../../assets/images/icons/edit.png";

const ProjectDetailsInfo = ({ projectDetail, handleEdit, qsnoimage }) => {
  const [showAllIndustries, setShowAllIndustries] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const userEmail = localStorage.getItem("userEmail");
  const project_closed = projectDetail?.closeproject === "y";

  // useEffect(() => {
  //   console.log("userEmail", userEmail);
  //   console.log("Managers", projectDetail?.managers);
  //   console.log("Parners", projectDetail?.partners);
  //   console.log("Principals", projectDetail?.principals);
  // }, []);

  const adminUserInTeam = projectDetail?.internalTeam.some(
    (member) =>
      member.email === userEmail &&
      ["project-manager", "project-partner", "project-principal"].includes(member.slug) &&
      member.status === "active"
  );
  
  const handleToggleIndustries = () => {
    setShowAllIndustries(!showAllIndustries);
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getTruncatedDescription = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) return text;
    return `${words.slice(0, wordLimit).join(" ")} ...`;
  };

  const descriptionText = showFullDescription
    ? projectDetail?.description
    : getTruncatedDescription(projectDetail?.description || "", 100);

  const isLocalhostImage = (url) => {
    const localhostPattern = /^(http:\/\/localhost|http:\/\/127\.0\.0\.1)/;
    return localhostPattern.test(url);
  };

  const imageUrl =
    projectDetail?.imageurl && !isLocalhostImage(projectDetail.imageurl)
      ? projectDetail.imageurl
      : qsnoimage;

  return (
    <div style={{ padding: "15px", maxWidth: "90%" }}>
      <Typography
        style={{
          display: "flex",
          fontFamily: "Poppins",
          alignItems: "center",
          fontSize: "32px",
          fontWeight: "550",
        }}
      >
        {projectDetail?.projectname}
        <span
          style={{
            marginLeft: "0.5rem",
            fontFamily: "Poppins",
            color: "grey",

            fontSize: "18px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ opacity: "60%" }}>qs_{projectDetail?.project_id}</span>
          {adminUserInTeam && !project_closed && (
            // <EditNoteIcon
            //   onClick={handleEdit}
            //   style={{ marginLeft: "5px", cursor: "pointer" }}
            // />
            <img
              src={EditIconPng}
              onClick={handleEdit}
              alt="edit"
              style={{ marginLeft: "0.5rem", cursor: "pointer" }}
            />
          )}
          {/* <EditNoteIcon
            onClick={handleEdit}
            style={{ marginLeft: "5px", cursor: "pointer" }}
          />  */}
        </span>
      </Typography>
      <div style={{ display: "flex", marginTop: "2.4%" }}>
        <img
          src={projectDetail?.imageurl ? projectDetail?.imageurl : qsnoimage}
          // src={imageUrl}
          alt="project-img"
          style={{
            width: "190px",
            height: "180px",
            marginTop: "0.5rem",
            marginBottom: "2.5rem",
            marginRight: "30px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "2rem",
            marginLeft: "0.5rem",
          }}
        >
          <div style={{ marginBottom: "5px", marginTop: "0.2rem" }}>
            <Typography
              variant="body2"
              style={{
                margin: 0,
                fontSize: "12px",
                color: "#9a9ea9",
                fontFamily: "Poppins",
              }}
            >
              Service Type:
            </Typography>
            {projectDetail?.servicetype
              ?.slice(
                0,
                showAllIndustries ? projectDetail.servicetype?.length : 2
              )
              .map((service, index) => (
                <Chip
                  key={index}
                  label={service.name}
                  style={{
                    marginTop: "5px",
                    fontSize: "11px",
                    height: "24px",
                    borderRadius: "5px",
                    marginRight: "0.5rem",
                    backgroundColor: "ebebeb",
                    fontFamily: "Poppins",
                    color: "#7a8093",
                  }}
                />
              ))}
          </div>

          <div style={{ marginBottom: "5px", marginTop: "0.2rem" }}>
            <Typography
              variant="body2"
              style={{
                margin: 0,
                fontSize: "12px",
                color: "#9a9ea9",
                fontFamily: "Poppins",
              }}
            >
              Industry:
            </Typography>
            {projectDetail?.industry
              ?.slice(0, showAllIndustries ? projectDetail.industry?.length : 2)
              .map((industry, index) => (
                <Chip
                  key={index}
                  label={industry.name}
                  style={{
                    marginTop: "5px",
                    fontSize: "11px",
                    fontFamily: "Poppins",
                    height: "24px",
                    borderRadius: "5px",
                    backgroundColor: "ebebeb",
                    color: "#7a8093",
                  }}
                />
              ))}
          </div>
          <div
            style={{
              marginBottom: "5px",
              marginTop: "0.2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body2"
              style={{
                margin: 0,
                fontSize: "12px",
                color: "#9a9ea9",
                fontFamily: "Poppins",
              }}
            >
              Sector of Work:
            </Typography>
            {projectDetail?.sectorwork
              ?.slice(
                0,
                showAllIndustries ? projectDetail.sectorwork?.length : 2
              )
              .map((sector, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: "5px",
                  }}
                >
                  <Chip
                    label={sector.name}
                    style={{
                      fontSize: "11px",
                      fontFamily: "Poppins",
                      height: "auto",
                      minHeight: "24px",
                      borderRadius: "5px",
                      backgroundColor: "#ebebeb",
                      color: "#7a8093",
                      padding: "0 10px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  />
                </div>
              ))}
            {projectDetail?.sectorwork?.length > 2 && (
              <button
                onClick={handleToggleIndustries}
                style={{
                  marginTop: "5px",
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                  color: "grey",
                  textDecoration: "none",
                }}
              >
                {showAllIndustries ? "See Less" : "See all"}
              </button>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <div style={{ marginBottom: "5px" }}>
            <p style={{ margin: 0, fontSize: "11px" }}>
              <span
                style={{
                  color: "#9a9ea9",
                  fontSize: "12px",
                  marginRight: "0.5rem",
                  fontFamily: "Poppins",
                }}
              >
                Location:
              </span>

              <span
                style={{
                  color: "#9a9ea9",
                  fontSize: "11px",
                  fontFamily: "Poppins",
                }}
              >
                {projectDetail?.projectlocation?.length > 0 &&
                  projectDetail?.projectlocation
                    .map((location) => location.location)
                    .join(", ")}
              </span>
            </p>
          </div>

          <div style={{ minWidth: "111%", maxWidth: "160%" }}>
            <p style={{ fontSize: "14px", fontFamily: "Poppins" }}>
              {descriptionText}
              {projectDetail?.description?.split(" ").length > 100 && (
                <button
                  onClick={handleToggleDescription}
                  style={{
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                    fontFamily: "Poppins",
                    color: "grey",
                    textDecoration: "none",
                  }}
                >
                  {showFullDescription ? "See Less" : "See All"}
                </button>
              )}
            </p>
          </div>
          <div style={{ marginBottom: "5px" }}>
            <div style={{ margin: 0, fontSize: "11px", fontFamily: "Poppins" }}>
              <span style={{ fontWeight: "600" }}>
                {" "}
                Prin. Client:
                {projectDetail?.principalclientform?.length > 0 &&
                  projectDetail?.principalclientform.map((client, index) => (
                    <Chip
                      key={index}
                      label={
                        client.name
                          ? client.name
                          : client.organization
                          ? client.organization
                          : client.client.name
                      }
                      style={{
                        fontSize: "12px",
                        height: "5px",
                        fontWeight: "Bold",
                        backgroundColor: "transparent",
                      }}
                    />
                  ))}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsInfo;
