import Swal from "sweetalert2";
import { employeeTeamStatus } from "../services/FormService";

export const getFilteredDesignations = (selectedMember, designations) => {
  if (!selectedMember) {
    return [];
  }
  let filteredDesignations = [];

  if (["B1"].includes(selectedMember.band)) {
    filteredDesignations = designations;
  } else if (["B5", "No Band"].includes(selectedMember.band)) {
    filteredDesignations = designations.filter(
      (designation) => designation.slug !== "project-partner"
    );
  } else {
    filteredDesignations = designations.filter(
      (designation) =>
        designation.slug !== "project-partner" &&
        designation.slug !== "off-the-project"
    );
  }

  filteredDesignations = filteredDesignations.filter(
    (designation) => designation.slug !== "consultant"
  );

  filteredDesignations = filteredDesignations.filter(
    (designation) => designation.slug !== "off-the-project"
  );

  return filteredDesignations.sort((a, b) => {
    const roleA = a.role ? a.role.toString() : "";
    const roleB = b.role ? b.role.toString() : "";
    return roleA.localeCompare(roleB);
  });
};

export const getFilteredConsultantDesignations = (
  selectedExternalMember,
  designations
) => {
  if (!selectedExternalMember) {
    return [];
  }

  let filteredDesignations = [...designations];

  if (selectedExternalMember.type === "Part Time Consultancy") {
    filteredDesignations = filteredDesignations.filter(
      (designation) =>
        ![
          "project-manager",
          "project-partner",
          "co-lead",
          "project-principal",
        ].includes(designation.slug)
    );
  }

  filteredDesignations = filteredDesignations.filter(
    (designation) => designation.slug !== "off-the-project"
  );

  return filteredDesignations.sort((a, b) => {
    return a.role.localeCompare(b.role);
  });
};

export const handleEditManager =
  (
    managerToEdit,
    setSelectedChip,
    setSelectedMember,
    setSelectedDesignation,
    setManagers,
    project_id
  ) =>
  async () => {
    if (managerToEdit.status === "inactive") {
      const result = await Swal.fire({
        html: `<div>
        <p>Are you sure you want to re-instate ${managerToEdit.name} to the project?</p>
        </div>`,
        showCancelButton: true,
        reverseButtons: true,
        icon: "question",
        customClass: {
          popup: "swal2-smaller-popup",
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-secondary",
        },
        confirmButtonText: "Yes, confirm!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        const data = {
          project_id: project_id.replace(/-/g, ""),
          employee_id: managerToEdit.id.replace(/-/g, ""),
          is_active: "true",
        };

        try {
          const response = await employeeTeamStatus(data);
          console.log("API response:", response.data);

          if (response.data.success) {
            const updatedManager = {
              ...managerToEdit,
              status: "active",
            };

            setManagers((prevManagers) =>
              prevManagers.map((manager) =>
                manager.email === managerToEdit.email ? updatedManager : manager
              )
            );
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to re-instate the manager. Please try again.",
            });
          }
        } catch (error) {
          console.error("Error re-instating manager:", error);

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An error occurred while trying to re-instate the manager.",
          });
        }
      }
      return;
    }

    setSelectedChip(managerToEdit);
    const nameParts = managerToEdit.name.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(" ");
    setSelectedMember({
      firstname: firstName,
      lastname: lastName,
      email: managerToEdit.email,
      slug: managerToEdit.slug,
      band: managerToEdit.band,
    });
    setSelectedDesignation({
      role: managerToEdit.role,
      slug: managerToEdit.slug,
    });
  };

  
// export const handleEditExternalMember =
// (
//   consultantToEdit,
//   setSelectedChip,
//   setSelectedMember,
//   setSelectedDesignation
// ) =>
// () => {
//   setSelectedChip(consultantToEdit);
//   const nameParts = consultantToEdit.name.split(" ");
//   const firstName = nameParts[0];
//   const lastName = nameParts.slice(1).join(" ");
//   setSelectedMember({
//     firstname: firstName,
//     lastname: lastName,
//     email: consultantToEdit.email,
//     slug: consultantToEdit.slug,
//     band: consultantToEdit.band,
//   });
//   setSelectedDesignation({
//     role: consultantToEdit.role,
//     slug: consultantToEdit.slug,
//   });
// };


export const handleEditExternalMember = (
  consultantToEdit,
  setSelectedChip,
  setSelectedMember,
  setSelectedDesignation,
  setConsultants, 
  project_id
) => 
async () => {
  if (consultantToEdit.status === "inactive") {
    console.log("consultantToEdit", consultantToEdit)
    const result = await Swal.fire({
      html: `<div>
        <p>Are you sure you want to re-instate ${consultantToEdit.name} to the project?</p>
        </div>`,
      showCancelButton: true,
      reverseButtons: true,
      icon: "question",
      customClass: {
        popup: "swal2-smaller-popup",
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      confirmButtonText: "Yes, confirm!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      const data = {
        project_id: project_id.replace(/-/g, ""),
        employee_id: consultantToEdit.id.replace(/-/g, ""),
        is_active: "true",
      };

      try {
        const response = await employeeTeamStatus(data);
        console.log("API response:", response.data);

        if (response.data.success) {
          const updatedConsultant = {
            ...consultantToEdit,
            status: "active",
          };

          setConsultants((prevConsultants) =>
            prevConsultants.map((consultant) =>
              consultant.email === consultantToEdit.email ? updatedConsultant : consultant
            )
          );

          // setSelectedChip(updatedConsultant);
          // const nameParts = updatedConsultant.name.split(" ");
          // const firstName = nameParts[0];
          // const lastName = nameParts.slice(1).join(" ");
          // setSelectedMember({
          //   firstname: firstName,
          //   lastname: lastName,
          //   email: updatedConsultant.email,
          //   slug: updatedConsultant.slug,
          //   band: updatedConsultant.band,
          // });
          // setSelectedDesignation({
          //   role: updatedConsultant.role,
          //   slug: updatedConsultant.slug,
          // });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to re-instate the consultant. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error re-instating consultant:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while trying to re-instate the consultant.",
        });
      }
    }
    return;
  }

  setSelectedChip(consultantToEdit);
  const nameParts = consultantToEdit.name.split(" ");
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");
  setSelectedMember({
    firstname: firstName,
    lastname: lastName,
    email: consultantToEdit.email,
    slug: consultantToEdit.slug,
    band: consultantToEdit.band,
  });
  setSelectedDesignation({
    role: consultantToEdit.role,
    slug: consultantToEdit.slug,
  });
};




export const handleDeleteManager =
  (
    managerToDelete,
    managers,
    setManagers,
    setSelectedMember,
    setSelectedDesignation,
    project_id
  ) =>
  async () => {
    console.log("Member to delete ", managerToDelete);

    const result = await Swal.fire({
      title: "Remove from team?",
      html: `<div >
    <p>Are you sure that you want to remove ${managerToDelete.name} from the team? Once removed, they will no longer be able to view or access project files.</p>
    <p style="text-align: left;">Please specify the reason:</p>
      <select id="reason" class="form-control">
        <option value="1">Added by mistake</option>
        <option value="2">Off the project</option>
        
      </select>
    </div>`,
      showCancelButton: true,
      confirmButtonText: "Remove",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        popup: "swal2-smaller-popup",
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: true,
    });

    if (result.isConfirmed) {
      const reason = document.getElementById("reason").value;

      if (reason === "2") {
        const updatedManagers = managers.map((manager) => {
          if (manager === managerToDelete) {
            return { ...manager, status: "inactive" };
          }
          return manager;
        });
        setManagers(updatedManagers);
        const data = {
          project_id: project_id.replace(/-/g, ""),
          employee_id: managerToDelete.id.replace(/-/g, ""),
          is_active: "false",
        };
        console.log("data", data);

        try {
          const response = await employeeTeamStatus(data);
          console.log("API response:", response.data);
        } catch (error) {
          console.error("Error updating employee team status:", error);
        }
      } else {
        setManagers((prevManagers) =>
          prevManagers.filter((manager) => manager !== managerToDelete)
        );
      }

      setSelectedMember(null);
      setSelectedDesignation(null);
    }
  };

  
  export const handleDeleteExternalMember = (
    memberToDelete,
    setConsultants,
    setSelectedExternalMember,
    setSelectedExternalDesignation,
    consultants,
    project_id
  ) => async () => {
    console.log("Member to delete ", memberToDelete);
  
    const result = await Swal.fire({
      title: "Remove from team?",
      html: `<div>
        <p>Are you sure that you want to remove ${memberToDelete.name} from the team? 
        Once removed, they will no longer be able to view or access project files.</p>
        <p style="text-align: left;">Please specify the reason:</p>
        <select id="reason" class="form-control">
          <option value="1">Added by mistake</option>
          <option value="2">Off the project</option>
        </select>
      </div>`,
      showCancelButton: true,
      confirmButtonText: "Remove",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        popup: "swal2-smaller-popup",
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: true,
    });
  
    if (result.isConfirmed) {
      const reason = document.getElementById("reason").value;
  
      if (reason === "2") {
        const updatedConsultants = consultants.map((consultant) =>
          consultant === memberToDelete
            ? { ...consultant, status: "inactive" }
            : consultant
        );
        setConsultants(updatedConsultants);
  
        const data = {
          project_id: project_id.replace(/-/g, ""),
          employee_id: memberToDelete.id.replace(/-/g, ""),
          is_active: "false",
        };
        console.log("data", data);
  
        try {
          const response = await employeeTeamStatus(data);
          console.log("API response:", response.data);
        } catch (error) {
          console.error("Error updating employee team status:", error);
        }
      } else {
        setConsultants((prevConsultants) =>
          prevConsultants.filter((consultant) => consultant !== memberToDelete)
        );
      }
  
      setSelectedExternalMember(null);
      setSelectedExternalDesignation(null);
    }
  };
  
