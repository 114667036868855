import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import qsnoimage from "../assets/noimage.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import projectService from "../services/ProjectService";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  clientName: {
    display: "none",
    "&:hover": {
      display: "block",
    },
  },
  card: {
    display: "flex",
    position: "relative",
  },
  media: {
    marginTop: "1rem",
    marginLeft: "0.32rem",
    padding: 3,
    width: 70,
    height: 69,
  },
  content: {
    flex: 1,
    marginLeft: "-0.5rem",
    paddingRight: theme.spacing(2),
  },
  deleteButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(-2),
    marginTop: "-0.2rem",
  },
}));

export default function ProjectImgMediaCard({
  image,
  title,
  projectId,
  onCardClick,
  clientName,
  description,
  locations,
  status,
  deleteId,
}) {
  const HandleOnProjectDelete = (event) => {
    // console.log("deleteId: ", deleteId);
    event.stopPropagation();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this project!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        projectService
          .deleteProject(deleteId)
          .then((response) => {
            // console.log("response", response);

            if (response.status === 200) {
              swal("Your project has been deleted!", {
                icon: "success",
              });
              window.location.reload();
            } else {
              console.error("Unexpected status code:", response.status);
              swal(
                "Oops!",
                "Something went wrong while deleting the project.",
                "error"
              );
            }
          })
          .catch((error) => {
            console.error("Error deleting project:", error);
            swal(
              "Oops!",
              "Something went wrong while deleting the project.",
              "error"
            );
          });
      }
    });
  };

  const classes = useStyles();

  const isSmallScreen = useMediaQuery("(max-width:1300px)");

  const minHeight = isSmallScreen ? "9rem" : "8rem"; // This is for card

  const maxHeight = isSmallScreen ? "8.5rem" : "7.5rem"; //This is for content

  const clientWidth = clientName?.length < 24 ? "13px" : "11px";

  return (
    <>
      <Card
        className={`${classes.card} ${
          status === "y"
            ? "custom-card-fetch-projects-closed"
            : "custom-card-fetch-projects"
        }  `}
        style={{ minHeight, minWidth: "17.5rem" }}
      >
        <CardMedia
          className={`${classes.media} card-image-styling`}
          component="img"
          alt="no image"
          height="60px"
          image={image ? image : qsnoimage}
        />
        <div
          style={{ maxHeight, minWidth: "12.58rem" }}
          className={classes.content}
        >
          <CardContent onClick={onCardClick}>
            <h5
              style={{
                fontSize: "18px",
                fontWeight: "560",
                fontFamily: "Poppins",
              }}
            >
              {title}

              <p
                style={{
                  fontSize: "12.5px",
                  fontFamily: "Poppins",
                  opacity: "80%",
                  fontWeight: "normal",
                  marginTop: "0.2rem",
                }}
              >
                {projectId}
                <br />

                <span style={{ fontSize: clientWidth }}>{clientName}</span>
              </p>
            </h5>

            <Button
              className={classes.deleteButton}
              style={{ fontSize: "18px", padding: "12px 24px" }}
              onClick={HandleOnProjectDelete}
            >
              <RiDeleteBin6Line color="black" />
            </Button>
          </CardContent>
        </div>
      </Card>
    </>
  );
}
