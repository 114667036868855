import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Chip,
  Modal,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import {
  EditNote as EditNoteIcon,
  AddToDrive as AddToDriveIcon,
} from "@mui/icons-material";
import GanttChartComponent from "../../pages/gantChart";
import googleDriveService from "../../services/GoogleDriveService";
import Swal from "sweetalert2";
import gdrive_icon from "../../assets/images/icons/drive.png";
import TimelineComponent from "../../pages/gantChart";
import projectService from "../../services/ProjectService";
import loader from "../../assets/images/loading.gif";
import internalImg from "../../assets/images/img4.png";
import externalImg from "../../assets/images/img1.png";
import EditNotePng from "../../assets/images/icons/edit.png";
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "40px",
  },
  "& .MuiInputLabel-root": {
    top: "-6px",
  },
});

const ManagementTab = ({
  uniqueTeamMembers,
  project_id,
  driveFolders,
  sortOption,
  handleSortChange,
  handleFolderClick,
  handleFolderShare,
  projectDetail,
  editing,
  setEditing,
  allInternalTeam,
  allExternalTeam,
}) => {
  const [folderUrl, setFolderUrl] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [linkedFolders, setLinkedFolders] = useState([]);

  const [linkedTimelines, setLinkedTimelines] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("userEmail");

  const project_closed = projectDetail?.closeproject === "y";

  const adminUserInTeam = projectDetail?.internalTeam.some(
    (member) =>
      member.email === userEmail &&
      ["project-manager", "project-partner", "project-principal"].includes(member.slug) &&
      member.status === "active"
  );
  

  const projectId = project_id;

  useEffect(() => {
    fetchLinkedFolders();
  }, [projectId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (projectId) {
      getLinkedTimelines();
    }
  }, [projectId]);

  const getLinkedTimelines = () => {
    const response = projectDetail?.linkedTimelines;

    setLinkedTimelines(response);
  };

  const fetchLinkedFolders = () => {
    const response = projectDetail?.linkedFolders;
    setLinkedFolders(response);
  };

  const handleNavigateTeam = () => {
    setEditing(true);
    navigate("/team?editing=true");
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  const linkFolder = async () => {
    setSubmitted(true);
    setShowModal(false);

    if (folderUrl.trim() === "") {
      return;
    }

    try {
      const folderIdRegex = /\/folders\/([^/?&]+)/;
      const matches = folderUrl.match(folderIdRegex);

      if (matches && matches.length >= 2) {
        let folderId = matches[1];

        folderId = folderId.split("?")[0];

        const existingFolder =
          linkedFolders &&
          linkedFolders.length > 0 &&
          linkedFolders.find((folder) => folder.id === folderId);

        if (!existingFolder) {
          await linkGoogleDriveFolder(projectDetail.project_id, folderId);
          console.log("folderId", folderId);
        } else {
          console.log("existing Folder id", existingFolder.id);
          Swal.fire({ title: "Folder already exists", icon: "info" });
        }
      } else {
        Swal.fire({ title: "Invalid Google Drive folder URL", icon: "error" });
      }

      setFolderUrl("");
      setSubmitted(false);
    } catch (error) {
      console.error("Error linking Google Drive folder:", error);
    }
  };

  const linkGoogleDriveFolder = async (projectId, folderId) => {
    setShowModal(false);
    try {
      const folderDetails = await googleDriveService.getFolderDetails(folderId);
      // console.log("folder details", folderDetails);
      if (folderDetails) {
        await googleDriveService.linkGoogleDriveFolder(
          projectId,
          folderDetails
        );

        setLinkedFolders((prevState = []) => {
          if (Array.isArray(prevState)) {
            return [...prevState, folderDetails.data];
          } else {
            console.error("Unexpected state value:", prevState);
            return [folderDetails.data];
          }
        });
        Swal.fire({ title: "Folder linked successfully", icon: "success" });
      } else {
        throw new Error("Folder details not found");
      }
    } catch (error) {
      console.error("Error linking Google Drive folder:", error);
      Swal.fire({ title: "Error linking Google Drive folder", icon: "error" });
    }
  };

  const handleFolderUrlChange = (event) => {
    setFolderUrl(event.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  // const handlePublishProject = () => {
  //   Swal.fire({
  //     text: "Project has been published",
  //     icon: "success",
  //   }).then(() => {
  //     navigate("/");
  //   });
  // };

  const toggleProjectStatus = async () => {
    try {
      if (projectDetail !== null && projectDetail.closeproject) {
        const confirmation = await Swal.fire({
          text: `Are you sure you want to ${
            projectDetail.closeproject === "y" ? "re-open" : "close"
          } the project?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });

        if (confirmation.isConfirmed) {
          setLoading(true);
          const newCloseProjectStatus =
            projectDetail.closeproject === "y" ? "n" : "y";
          await projectService.updateProjectByProjectId(
            {
              ...projectDetail,
              closeproject: newCloseProjectStatus,
            },
            projectDetail._id
          );
          projectDetail.closeproject = newCloseProjectStatus;
          setLoading(false);
        }
      } else {
        setLoading(false);
        console.error(
          "Project detail is null or does not have closeproject property."
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error toggling project status:", error);
    }
  };

  const getFirstThreeWords = (name) => {
    const nameParts = name.split(" ");
    return nameParts.slice(0, 3).join(" ");
  };

  return (
    <Grid container spacing={2}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loader}
            alt="Loading..."
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        </div>
      )}

      <Grid item xs={12} md={3}>
        <p className="management-team-styling">
          Team
          {adminUserInTeam && !project_closed && (
            <img
              src={EditNotePng}
              alt="edit"
              style={{ cursor: "pointer", marginLeft: "0.5rem" }}
              onClick={handleNavigateTeam}
            />
          )}
          {/* <img
            src={EditNotePng}
            alt="edit"
            style={{ cursor: "pointer", marginLeft: "0.5rem" }}
            onClick={handleNavigateTeam}
          /> */}
        </p>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 0,
            maxWidth: "20rem",
          }}
        >
          {allInternalTeam.map((member, index) => {
            const nameParts = member.name.split(" ");
            const displayName = `${nameParts[0]} ${nameParts[1] || ""}`.trim();

            return (
              <div
                key={`internal-${index}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={internalImg}
                  alt="Internal team"
                  style={{ marginRight: "0.5px" }}
                />
                <Chip
                  label={displayName}
                  style={{
                    backgroundColor: "transparent",
                    fontFamily: "Poppins",
                    fontSize: "13px",
                  }}
                />
              </div>
            );
          })}

          {allExternalTeam.map((member, index) => {
            const displayName = getFirstThreeWords(member.name);

            return (
              <div
                key={`external-${index}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={externalImg}
                  alt="External team"
                  style={{ marginRight: "0.5px" }}
                />
                <Chip
                  label={displayName}
                  style={{
                    backgroundColor: "transparent",
                    fontFamily: "Poppins",
                    fontSize: "13px",
                  }}
                />
              </div>
            );
          })}
        </div>
      </Grid>

      <Grid style={{ marginLeft: "2.5rem" }} item xs={12} md={8}>
        <div>
          <TimelineComponent
            projectId={project_id}
            linkedTimelines={linkedTimelines}
            setLinkedTimelines={setLinkedTimelines}
            loading={loading}
            setLoading={setLoading}
            projectDetail={projectDetail}
            toggleProjectStatus={toggleProjectStatus}
            adminUserInTeam={adminUserInTeam}
            project_closed={project_closed}
            // userInManagers={userInManagers}
            // userInPartners={userInPartners}
            // userInPrincipals={userInPrincipals}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <h6 style={{ marginTop: "20px" }}>
          <img src={gdrive_icon} alt="google-drive" />
          <span
            style={{
              marginLeft: "0.5rem",
              marginTop: "0.5rem",
              fontWeight: "600",
            }}
          >
            Project Folder Set-up
          </span>
        </h6>

        <button
          style={{
            backgroundColor: "#e5e5e5",
            paddingRight: "2rem",
            paddingLeft: "2rem",
            color: "grey",
            fontFamily: "Poppins",
          }}
          className="btn"
          onClick={handleOpenModal}
        >
          Set-up Folders
        </button>

        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "55rem",
              bgcolor: "background.paper",
              border: "1px solid #000",
              borderRadius: "10px",
              boxShadow: 24,
              p: 2,
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Link G-Drive folders
            </div>
            <p style={{ fontFamily: "Poppins", fontSize: "14px" }}>
              <br />
              1. Go to{" "}
              <a
                style={{ textDecoration: "none" }}
                href="https://drive.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                G<span style={{ color: "red" }}>oo</span>
                <span style={{ color: "green" }}>gl</span>e Drive
              </a>{" "}
              and share{" "}
              <span style={{ color: "brown" }}>“archives@quicksand.co.in”</span>{" "}
              on the project folder as an Editor. If the folder does not exist,
              then create the project folder in G-Drive and do this step.
              <br />
              2. Once shared, change the ownership of the folder to{" "}
              <span style={{ color: "brown" }}>“archives@quicksand.co.in”</span>
              .
              <br />
              3. Enter the URL of the Google Drive Project Folder and press
              “Link”
            </p>

            <CustomTextField
              fullWidth
              placeholder="Paste Google Drive folder URL here"
              value={folderUrl}
              onChange={handleFolderUrlChange}
              required
            />

            <div className="float-end" style={{ marginTop: "1rem" }}>
              <h6
                className="btn submit-btn "
                style={{
                  marginRight: "0.5rem",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "purple",
                }}
                onClick={linkFolder}
              >
                Link
              </h6>
              <h6
                onClick={handleCloseModal}
                className="btn submit-btn"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Close
              </h6>
            </div>
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8}>
        <div className="row drive-folders ">
          {linkedFolders &&
            linkedFolders.map((driveItem) => (
              <div
                key={driveItem.id}
                className="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3"
              >
                <div
                  onClick={() => handleFolderClick(driveItem.id)}
                  className="card"
                >
                  <div className="card-body" role="button">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-center">
                          <img
                            className="img-fluid"
                            src={gdrive_icon}
                            alt="drive-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-2 ms-1 mb-1">{driveItem.name}</h6>
                </div>
              </div>
            ))}
        </div>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export default ManagementTab;
